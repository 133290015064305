@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .l-col2__wrap {
  z-index: 1; }

#top .l-col2--side {
  z-index: 1; }

#top .l-col2__txt {
  max-width: 592px; }

#top .p-img-position--top {
  background-position: top; }

#top .p-img-position--right {
  background-position-x: right; }

#top .p-img-position--center {
  background-position: center; }
  @media screen and (max-width: 768px) {
    #top .p-img-position--center-sm {
      background-position: center; } }

#top .p-img-position--inherit {
  background-position: inherit; }
  @media screen and (max-width: 768px) {
    #top .p-img-position--inherit-sm {
      background-position: inherit; } }

#top .p-ttl--news {
  margin-bottom: 50px; }

#top .p-ttl--ml15 {
  margin-left: 15px; }

#top .p-ttl--inline {
  display: inline-block; }

#top .p-ttl--top {
  padding-top: 2rem;
  color: #0a7b5c;
  margin-bottom: 0; }
  @media screen and (max-width: 568px) {
    #top .p-ttl--top {
      padding-top: 1.4rem; } }
  #top .p-ttl--top > .p-ttl__txt {
    display: block;
    width: 75%;
    padding-bottom: 10px;
    max-width: inherit;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .3em;
    line-height: 1.82; }
    @media screen and (min-width: 1800px) {
      #top .p-ttl--top > .p-ttl__txt {
        font-size: 4rem; } }
    @media screen and (max-width: 1280px) {
      #top .p-ttl--top > .p-ttl__txt {
        font-size: 2.4rem; } }
    @media screen and (max-width: 1024px) {
      #top .p-ttl--top > .p-ttl__txt {
        font-size: 1.9rem; } }
    @media screen and (max-width: 568px) {
      #top .p-ttl--top > .p-ttl__txt {
        font-size: 1.6rem; } }
  #top .p-ttl--top > .p-ttl__sub {
    display: block;
    width: 65%;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .2em;
    line-height: 1.72; }
    @media screen and (min-width: 1800px) {
      #top .p-ttl--top > .p-ttl__sub {
        font-size: 2.3rem; } }
    @media screen and (max-width: 1280px) {
      #top .p-ttl--top > .p-ttl__sub {
        font-size: 1.5rem; } }
    @media screen and (max-width: 1024px) {
      #top .p-ttl--top > .p-ttl__sub {
        font-size: 1rem; } }
    @media screen and (max-width: 568px) {
      #top .p-ttl--top > .p-ttl__sub {
        font-size: .8rem; } }

@media screen and (max-width: 768px) {
  #top .p-section--pt20 {
    padding-top: 20px; } }

@media screen and (max-width: 568px) {
  #top .p-section--sm-pt0 {
    padding-top: 0; } }

@media screen and (max-width: 568px) {
  #top .p-section--sm-pb50 {
    padding-bottom: 50px; } }

#top .p-section--hld {
  padding: 0 0 90px; }

#top .p-section__col2 {
  padding-right: 0;
  padding-left: 0; }

#top .p-section--center {
  margin-right: auto;
  margin-left: auto; }

#top .p-reason-bg {
  position: relative;
  overflow-x: clip; }
  #top .p-reason-bg__section:before {
    content: "";
    position: absolute;
    width: 1903px;
    height: 802px;
    background-image: url("/inc/image/common/bg_wave.png");
    background-position: center;
    background-size: cover;
    top: -58%;
    right: -350px; }
    @media screen and (max-width: 768px) {
      #top .p-reason-bg__section:before {
        top: -18%;
        right: -250px;
        width: 1021px;
        height: 431px; } }

#top .p-main-image {
  min-height: calc(100vh - 88px);
  height: 100%;
  background-image: url("/inc/image/top/img_mainVisual-wide.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: center; }
  @media screen and (max-width: 768px) {
    #top .p-main-image {
      min-height: calc(100vh - 60px);
      background-image: url("/inc/image/top/img_mainVisual-sp.jpg");
      background-size: cover;
      background-position-x: 71%;
      background-position-y: 55%; } }
  #top .p-main-image__wrap {
    overflow: hidden; }
  #top .p-main-image__ttl-wrap {
    padding-left: 4%; }
    @media screen and (max-width: 768px) {
      #top .p-main-image__ttl-wrap {
        padding-top: 1%; } }
